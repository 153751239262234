import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import styledContainerQuery from 'styled-container-query'
import ContainerArticle from '../../../../components/ContainerArticle'
import TitlePage from '../../../../components/TitlePage'
import ImageRadarRainfall from './components/ImageRadarRainfall'
import ControlsRadarRainfall from './components/ControlsRadarRainfall'
import ScaleRadarRainfall, { ImageMap0ScaleRadarRainfall } from './components/ScaleRadarRainfall'
import moment from 'moment/moment'
import InfoRadarRainfall from './components/InfoRadarRainfall'
import translate from '../../../../modules/translate'
import ContainerAd from '../../../../components/ContainerAd'

const TIME_FORMAT = 'HH:mm'

const StyledInfoRadarRainfall = styled(InfoRadarRainfall)`
  padding-top: 1.5em;
`
const WrapperRadarImages = styled.div`
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  img {
    width: 100%;
  }
`

const StyledScaleRadarRainfall = styled(ScaleRadarRainfall)`
  padding: 7px;
  box-sizing: border-box;
`

const StyledControlsRadarRainfall = styled(ControlsRadarRainfall)`
  margin-top: 15px;
`

const WrapperRadar = styledContainerQuery.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.color.background};
  margin-top: 2em;
  ${ImageMap0ScaleRadarRainfall}{
    min-height: 550px;
  }
  ${WrapperRadarImages} {
    width: 624px;

    &:before {
      content: " ";
      padding-top: 100%;
      display: block;
      float: left;
    }
  }

  &:container(max-width: 725px) {
    ${WrapperRadarImages} {
      width: 498px;
      margin-right: 15px;
    }
    ${ImageMap0ScaleRadarRainfall}{
      min-height: 427px
    }
  }
  &:container(max-width: 599px) {
    ${WrapperRadarImages} {
      width: 100%;
      margin-right: 0;
    }
    ${StyledScaleRadarRainfall} {
      width: 100%;
    }
    ${ImageMap0ScaleRadarRainfall}{
      min-height: unset;
    }
  }
`

const Wrapper = styled.div`
  padding-top: ${(props) => props.theme.sizes.gapVertical};
  padding-bottom: 3em;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
`

class RadarRainfall extends Component {
  constructor(props) {
    super(props)
    this.play = this.play.bind(this)
    this.stop = this.stop.bind(this)
    this.toggle = this.toggle.bind(this)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.state = {
      slideIndex: 0,
      count: props.items.length,
    }
    this.intervalId = undefined
  }

  componentDidMount() {
    this.play()
  }

  componentWillUnmount() {
    this.stop()
  }

  play() {
    this.intervalId = setInterval(() => {
      this.next()
    }, 410)
    this.playIcon(false)
  }

  stop() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = undefined
    }
    this.playIcon(true)
  }

  playIcon(mode) {
    this.setState({
      playIcon: mode,
    })
  }

  toggle() {
    if (!this.intervalId) {
      this.play()
    } else {
      this.stop()
    }
  }

  next() {
    let slideIndex = this.state.slideIndex + 1
    if (slideIndex >= this.state.count) {
      slideIndex = 0
    }

    this.setState({ slideIndex })
  }

  previous() {
    let slideIndex = this.state.slideIndex - 1
    if (slideIndex < 0) {
      slideIndex = this.state.count - 1
    }
    this.setState({ slideIndex })
  }

  render() {
    const { items, ...props } = this.props
    const currentRadar = items[this.state.slideIndex]
    const dateCurrentRadar = currentRadar?.date
    const timeCurrentRadar = moment(dateCurrentRadar).format(TIME_FORMAT)
    return (
      <Wrapper {...props}>
        <ContainerArticle>
          <TitlePage>{translate('weather_rainfall_radar')}</TitlePage>
          <WrapperRadar>
            <WrapperRadarImages>
              <ImageRadarRainfall
                image={currentRadar?.image + '?t=' + Math.random().toString().replace('0.', '')}
                alt={`${dateCurrentRadar} ${timeCurrentRadar}`}
              />
            </WrapperRadarImages>
            <StyledScaleRadarRainfall {...props} />
          </WrapperRadar>
          <StyledControlsRadarRainfall
            playIcon={this.state.playIcon}
            playPauseClick={this.toggle}
            previousClick={() => {
              this.stop()
              this.previous()
            }}
            nextClick={() => {
              this.stop()
              this.next()
            }}
            slider={{
              onChange: (newValue) => {
                this.stop()
                this.setState({ slideIndex: newValue })
              },
              value: this.state.slideIndex,
              text: '', //timeCurrentRadar,
              min: 0,
              max: items.length - 1,
            }}
            {...props}
          />
          <StyledInfoRadarRainfall
            title={translate('weather_rainfall_radar_description_header')}
            text={translate('weather_rainfall_radar_description')}
          />
        </ContainerArticle>
        <ContainerAd types={['S3', 'S4']} />
      </Wrapper>
    )
  }
}

RadarRainfall.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }),
  ),
}

export default RadarRainfall

export {
  Wrapper as RadarRainfall0Wrapper,
  WrapperRadar as RadarRainfall0WrapperRadar,
  WrapperRadarImages as RadarRainfall0WrapperRadarImages,
  TitlePage as RadarRainfall0StyledTitlePage,
  StyledScaleRadarRainfall as ScaleRadarRainfall0StyledScaleRadarRainfall,
  ContainerArticle as RadarRainfall0ContainerArticle,
}
