import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import ContainerFull from '../ContainerFull'
import AdUnit from '../AdUnit'

const StyledAdUnits = styled(AdUnit)`
  > div.adm-ad-empty {
    height: 0;
    width: 0;
  }
  > div {
    margin-top: ${(props) => props.theme.sizes.gapVertical};
    margin-left: ${(props) =>
      props.noGapEdge
        ? '0'
        : css`
            ${(props) => props.theme.sizes.gapColHalf};
          `};
    margin-right: ${(props) =>
      props.noGapEdge
        ? '0'
        : css`
            ${(props) => props.theme.sizes.gapColHalf};
          `};
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    > div {
      margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
      margin-left: ${(props) =>
        props.noGapEdge
          ? '0'
          : css`
              ${(props) => props.theme.tablet.sizes.gapColHalf};
            `};
      margin-right: ${(props) =>
        props.noGapEdge
          ? '0'
          : css`
              ${(props) => props.theme.tablet.sizes.gapColHalf};
            `};
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    > div {
      margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
      margin-left: ${(props) =>
        props.noGapEdge
          ? '0'
          : css`
              ${(props) => props.theme.mobile.sizes.gapColHalf};
            `};
      margin-right: ${(props) =>
        props.noGapEdge
          ? '0'
          : css`
              ${(props) => props.theme.mobile.sizes.gapColHalf};
            `};
    }
  }
`

const Wrapper = styled(ContainerFull)`
  display: flex;
  justify-content: center;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-wrap: wrap;
    flex-basis: 100%;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-wrap: wrap;
    flex-basis: 100%;
  }
`

const ContainerAd = ({ adIds, noGapEdge, mode, ...props }) => (
  <Wrapper noGapEdge={noGapEdge} {...props}>
    {adIds.map((adId, index) => {
      return <StyledAdUnits key={'ContainerAd' + index} adId={adId} mode={mode} noGapEdge={noGapEdge} />
    })}
  </Wrapper>
)

ContainerAd.propTypes = {
  adIds: PropTypes.arrayOf(AdUnit.propTypes.adId).isRequired,
  mode: PropTypes.oneOf(['desktop', 'desktop-tablet', 'desktop-mobile', 'tablet', 'tablet-mobile', 'mobile']),
}

ContainerAd.defaultProps = {
  adIds: [],
}

export default ContainerAd

export { StyledAdUnits as ContainerAd0StyledAdUnits }
