import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import Title from '../../../../../../components/Title'
import AddComments from './components/AddComments'
import translate from '../../../../../../modules/translate'
import ListComments from './components/ListComments'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router'
import LoadMore from '../../../../../../components/LoadMore'
import ErrorBoundary from '../../../../../Error/widgets/ErrorBoundary'
import { useUserContext } from '../../../../../../components/ContextUser'
import showModalId from '../../../../../../vendor/Piano/modules/show-modal-id'
import routes from '../../../../../routes'
import Link from '../../../../../../components/Link'

const Wrapper = styled.div`
  @media print {
    display: none;
  }
  margin-top: 40px;
  padding-bottom: 20px;
  padding-top: 20px;
  font-family: ${(props) => props.theme.font.primary};
  font-size: ${(props) => props.theme.fontSize.base};
  border-top: 1px solid ${(props) => props.theme.color.skeletonScreen};
  span {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: ${(props) => props.theme.fontWeight.bold};
  }
`

const StyledSubTitle = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.title3};
  @media(max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}){
    font-size: ${(props) => props.theme.tablet.fontSize.title3};
  }
  @media(max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}){
    font-size: ${(props) => props.theme.mobile.fontSize.title3};
  }
  color: ${(props) => props.theme.color.stol};
  font-weight: ${(props) => props.theme.fontWeight.regular}
  margin-top: 0;
  margin-bottom: 15px;
`
const StyledTitle = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.titleBigger};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.titleBigger};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.titleBigger};
  }
  color: ${(props) => props.theme.color.textSecondary};
  margin-bottom: 0;
`

const StyledLink = styled(Link)`
  font-size: ${(props) => props.theme.fontSize.small};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
`

const InfoWarning = styled.div`
  font-weight: bold;
  margin-top: 5px;
  font-family: ${(props) => props.theme.font.primary};
  a {
    font-size: ${(props) => props.theme.fontSize.base};
  }
`

const InfoWarningNotBold = styled.div`
  margin-top: 5px;
  font-family: ${(props) => props.theme.font.primary};
`

const StyledShowComments = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
  font-family: 'Splus Icon', 'Fira Sans', sans-serif;
  cursor: pointer;
`

const CommentsDetailArticle = ({
  articleId,
  noTitle,
  comments,
  commentsEnabled,
  commentsSubtitle,
  isCondulence,
  plus,
  ...props
}) => {
  let reversedComments = comments && Array.from(comments)
  const location = useLocation()
  const [commentText, setCommentText] = useState(null)
  const [currentComments, setCurrentComments] = useState(reversedComments ? reversedComments.reverse() : [])
  const [hideLoadMore, setHideLoadMore] = useState(false)
  const { profile } = useUserContext()
  const [showComments, setShowComments] = useState(false)
  const {
    access: { isPlus, isStandard },
  } = useUserContext()

  useEffect(() => {
    if (location && location.state && location.state.data) {
      setCommentText(location.state.data)
    }
  }, [location])

  if (plus && !isPlus) return false

  const textComment = (e) => {
    setCommentText(e.target.value.replace(/\r?\n/g, '<br />'))
  }

  const onSendCommentSuccess = ({ data }) => {
    setCommentText('')
  }

  const commentData = {
    articleId,
    comment: commentText,
  }

  return (
    <ErrorBoundary info={translate('dev_errorlog_addcomment')}>
      {commentsEnabled && (
        <React.Fragment>
          {profile && profile.verified && (
            <Wrapper {...props}>
              {!noTitle && commentsEnabled && isCondulence === false && (
                <StyledTitle tag="div">{translate('comments_title')}</StyledTitle>
              )}
              {!showComments && (
                <StyledShowComments
                  onClick={() => {
                    setShowComments(true)
                  }}
                >
                  &gt;&gt; {translate('show_comments')} &lt;&lt;
                </StyledShowComments>
              )}
              {commentsEnabled && showComments && (
                <Fragment>
                  <StyledSubTitle tag="div">
                    {commentsSubtitle
                      ? commentsSubtitle
                      : isCondulence
                      ? translate('condulence_subtitle')
                      : translate('comments_subtitle')}
                  </StyledSubTitle>
                  <AddComments
                    onChange={textComment}
                    data={commentData}
                    onSuccess={onSendCommentSuccess}
                    hasAccess={isStandard}
                    customCommentValue={location && location.state && location.state.data && location.state.data}
                    autoFocus={location && location.state && location.state.data ? true : false}
                  />
                </Fragment>
              )}
              {currentComments && showComments && <ListComments comments={currentComments} />}
              {showComments && comments && comments.length === 30 && hideLoadMore === false && (
                <LoadMore
                  articles={currentComments}
                  loadMoreRoute="/comments"
                  afterLoadMore={(newArticles) => {
                    if (currentComments && currentComments.length === newArticles.length) {
                      setHideLoadMore(true)
                    }
                    setCurrentComments(newArticles.reverse())
                  }}
                />
              )}
            </Wrapper>
          )}
          {!profile && (
            <Wrapper>
              {' '}
              {!noTitle && commentsEnabled && isCondulence === false && (
                <StyledTitle tag="div">{translate('comments_title')}</StyledTitle>
              )}
              {isCondulence && <StyledSubTitle tag="div">{translate('condulence_subtitle')}</StyledSubTitle>}
              {!profile && (
                <InfoWarningNotBold>
                  <StyledLink onClick={showModalId}>{translate('comments_add_signin1') + ' '}</StyledLink>
                  {translate('comments_add_signin2')}
                </InfoWarningNotBold>
              )}
            </Wrapper>
          )}
          {profile && !profile.verified && (
            <Wrapper>
              {' '}
              {!noTitle && commentsEnabled && isCondulence === false && (
                <StyledTitle tag="div">{translate('comments_title')}</StyledTitle>
              )}
              {isCondulence && <StyledSubTitle tag="div">{translate('condulence_subtitle')}</StyledSubTitle>}
              {profile && `${translate('logged_in_as')} ${profile.firstName} ${profile.lastName}`}
              {profile && !profile.verified && (
                <InfoWarning>
                  {translate('verification_message', { customMessage: translate('comments_verification') })}
                  <StyledLink to={routes.generatePathByRouteName('account')}> {translate('user_account')}</StyledLink>
                </InfoWarning>
              )}
            </Wrapper>
          )}
        </React.Fragment>
      )}
    </ErrorBoundary>
  )
}

CommentsDetailArticle.propTypes = {
  articleId: PropTypes.number,
  noTitle: PropTypes.bool,
  comments: [],
}

export default CommentsDetailArticle
