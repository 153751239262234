import React, { memo } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import AdUnit from '../AdUnit'

const WrapperLink = styled.a`
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

const Wallpaper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: no-repeat fixed center;
  z-index: -1;
`
const StyledAdUnit = styled(AdUnit)`
  #dlab_ad_WALLPAPER {
    display: block;
    position: absolute;
    width: 1px;
    height: 1px;
    top: 0;
    left: 0;
  }
`

const AdWallpaper = ({ ...props }) => {
  const GlobalStyle = createGlobalStyle`
      #dlab_ad_WALLPAPER {
        display: block;
        position: absolute;
        width: 1px;
        height: 1px;
        top: 0;
        left: 0;
        
        @media print {
          display: none;
        }
      }
  `

  return (
    <>
      <GlobalStyle />
      <div id="dlab_ad_WALLPAPER" />
      <WrapperLink className="wallpaper-link" {...props}>
        <Wallpaper className="wallpaper" />
      </WrapperLink>
    </>
  )
}

export default memo(AdWallpaper)
